/** @type {import('vue').PluginObject} */
export default {
  install(Vue) {
    Vue.directive('focus', {
      inserted: function(el) {
        if (el.querySelector('p.tribute-input')) {
          el.querySelector('p.tribute-input').focus()
        } else if (el.tagName === 'DIV') {
          el.querySelector('input').focus()
        } else {
          el.focus()
        }
      }
    })

    Vue.directive('click-outside', {
      bind: function(el, binding) {
        const bubble = binding.modifiers.bubble
        const handler = e => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e)
          }
        }
        el.__vueClickOutside__ = handler
        document.addEventListener('click', handler)
      },
      unbind: function(el) {
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null
      }
    })
  }
}