import { ANSWER_SEARCH_SORT_OPTIONS } from '@/constants/constants'

export default {
  namespaced: true,
  state: {
    dialogs: [],
    searchQuery: [],
    selectedSearchSorting: ANSWER_SEARCH_SORT_OPTIONS[0],
    searchOffset: 1,
    totalCount: 0,
  },
  getters: {
    dialogs: state => state.dialogs,
    searchQuery: state => state.searchQuery,
    selectedSearchSorting: state => state.selectedSearchSorting,
    searchOffset: state => state.searchOffset,
    totalCount: state => state.totalCount,
  },
  mutations: {
    SET_DIALOGS(state, dialogs) {
      state.dialogs = dialogs
    },
    SET_SEARCH_QUERY(state, searchQuery) {
      state.searchQuery = searchQuery
    },
    SET_SELECTED_SEARCH_SORTING(state, selectedSearchSorting) {
      state.selectedSearchSorting = selectedSearchSorting
    },
    SET_SEARCH_OFFSET(state, searchOffset) {
      state.searchOffset = searchOffset
    },
    SET_TOTAL_COUNT(state, totalCount) {
      state.totalCount = totalCount
    },
  },
  actions: {
    RESET_SEARCH({ commit }, searchQuery) {
      commit('SET_SEARCH_QUERY', searchQuery)
      commit('SET_SEARCH_OFFSET', 1)
    },
  }
}

