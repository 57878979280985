import utils from '@/assets/utils'
import vxDialog from '@/components/vx-dialog/VxDialog.vue'
import { defineComponent } from 'vue'

/** @type {import('vue').ObjectPlugin} */
export default {
  install(Vue) {
    /**
     * Invoke the Dialog component
     * 
     * @param {object} params 
     */
    function dialog (params) {
      /**
       * Extended instance of the Dialog component
       */
      const component = defineComponent({
        extends: vxDialog,
        data() {
          return {
            isPrompt: false,
          }
        },
        mounted() {
          this.isActive = true;
        },
      })

      utils.mount(component, {
        app: Vue,
        props: {
          accept: params.accept,
          cancel: params.cancel,
          text: params.text,
          title: params.title,
          color: params.color,
          type: params.type,
          buttonAccept: params.buttonAccept,
          buttonCancel: params.buttonCancel,
          acceptText: params.acceptText,
          cancelText: params.cancelText,
          closeIcon: params.closeIcon,
          isValid: params.isValid
        }
      });
    }

    Vue.config.globalProperties.$dialog = dialog
  }
} 