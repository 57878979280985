import { themeConfig, themeColors } from '@/utils/themeConfig'

const session = localStorage.session && JSON.parse(localStorage.session)

export function appActiveUserFactory() {
  return {
    id: 0,
    name: 'John Doe',
    about:
      'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
    img: 'avatar-s-11.png',
    status: 'online'
  }
}

export function companyLogoFactory() {
  return session?.theme?.companyLogo || ''
}

export function themeFactory() {
  return session?.theme?.name || themeConfig.theme
}

export function themeColorsFactory() {
  return session?.theme?.themeColors || themeColors
}

export function themePrimaryColorFactory() {
  return session?.theme?.themeColors?.primary || themeColors.primary
}

export function languagesFactory() {
  return [
    {
      title: 'Español',
      code: 'es'
    },
    {
      title: 'English',
      code: 'en'
    },
    {
      title: 'Português',
      code: 'pt'
    }
  ]
}

export function channelsFactory() {
  return [
    {
      type: 'web',
      name: 'Konecta',
      active: true,
      enabled: false
    }
  ]
}

export function chatTransitionSettingsFactory() {
  return {
    transfer: {
      tagging: 'disable',
      taggingType: 'single',
      tags: [],
      comment: 'disable',
      transitionMessage: 'disable',
      defaultTransitionMessage: ''
    },
    finish: {
      tagging: 'disable',
      taggingType: 'single',
      tags: [],
      comment: 'disable',
      transitionMessage: 'disable',
      defaultTransitionMessage: ''
    }
  }
}

export function chatGeneralSettingsFactory() {
  return {
    releasedPriority: 0,
    xThreshold: 0,
    xyPriority: 0,
    yThreshold: 0,
    yzPriority: 0
  }
}

export function botTimeoutMessageValidationFactory() {
  return {
    askAgain: 2,
    trigger: {
      threshold: 60
    },
    type: 'longStandingTakeover',
    service: {
      name: ''
    },
    answer: 'buttons', // handled in backend
    bot: null, //botId
    question: '',
    positive: {
      text: 'Sí',
      actions: [
        {
          key: 'stopTakeover',
          args: []
        }
      ]
    },
    negative: {
      text: 'No',
      actions: [
        {
          key: 'stopTakeover',
          args: []
        }
      ]
    },
    replyWhileWaitingForAgent: false
  }
}
