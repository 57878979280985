import { createVNode, render } from "vue"

export default {
  insertBody(elx) {
    document.body.insertBefore(elx, document.body.firstChild)
  },
  removeBody(element) {
    document.body.removeChild(element)
  },
  mount(component, { props, children, element, app } = {}) {
    let el = element

    let vNode = createVNode(component, props, children)
    if (app && app._context) vNode.appContext = app._context
    if (el) render(vNode, el)
    else if (typeof document !== 'undefined' ) render(vNode, el = document.createElement('div'))

    const destroy = () => {
      if (el) render(null, el)
      el = null
      vNode = null
    }

    return { vNode, destroy, el }
  },
}
