<template>
  <td class="td vs-table--td">
    <span>
      <slot />
    </span>
  </td>
</template>

<script>
export default {
  name: 'VsTd',
}
</script>
