export default {
  pageTitle: {
    en: 'Answer Builder',
    es: 'Constructor de Respuestas',
    pt: 'Construtor de Respostas',
  },
  answers: {
    es: 'Lista de Respuestas',
    en: 'Answers List',
    pt: 'Lista de Respostas',
  },
  searchOldText: {
    es: 'Buscar respuestas',
    en: 'Search answers',
    pt: 'Pesquisa respostas',
  },
  search: {
    es: 'Buscar',
    en: 'Search',
    pt: 'Pesquisa',
  },
  searchPlaceholder: {
    en: 'Enter text or choose criteria to refine your search…',
    es: 'Introduce texto o elige criterios para refinar tu búsqueda…',
    pt: 'Digite o texto ou escolha critérios para refinar sua pesquisa…',
  },
  filters: {
    es: 'Filtros',
    en: 'Filters',
    pt: 'Filtros',
  },
  noMoreAnswersToShow: {
    es: 'No hay más respuestas para mostrar',
    en: 'No more answers to show',
    pt: 'Não há mais respostas para mostrar',
  },
  noResultsFound: {
    title: {
      en: "Sorry! No results found",
      es: "¡Lo sentimos! No se encontraron resultados",
      pt: "Desculpe! Não foram encontrados resultados"
    },
    description: {
      en: "Try adjusting the filters or search terms to find what you're looking for.",
      es: "Intenta ajustar los filtros o términos de búsqueda para encontrar lo que buscas.",
      pt: "Tente ajustar os filtros ou termos de pesquisa para encontrar o que procura."
    }
  },
  sortAnswers: {
    es: 'Ordenar respuestas',
    en: 'Sort answers',
    pt: 'Ordenar respostas'
  },
  sortBy: {
    es: 'Ordenar por',
    en: 'Sort by',
    pt: 'Ordenar por'
  },
  sortingLabels: {
    name: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome'
    },
    oldest: {
      es: 'Más antiguo',
      en: 'Oldest',
      pt: 'Mais antigo'
    },
    newest: {
      es: 'Más reciente',
      en: 'Newest',
      pt: 'Mais recente'
    },
    ascending: {
      es: 'Ascendente',
      en: 'Ascending',
      pt: 'Ascendente'
    },
    descending: {
      es: 'Descendente',
      en: 'Descending',
      pt: 'Descendente'
    }
  },
  applyFilters: {
    es: 'Aplicar filtros',
    en: 'Apply filters',
    pt: 'Aplicar filtros'
  },
  answerStatus: {
    es: 'Inactivo',
    en: 'Inactive',
    pt: 'Inativo'
  },
  tags: {
    es: 'Etiquetas',
    en: 'Tags',
    pt: 'Etiquetas'
  },
  tag: {
    es: 'Etiqueta',
    en: 'Tag',
    pt: 'Etiqueta'
  },
  count: {
    es: 'Ocurrencias',
    en: 'Count',
    pt: 'Ocorrências'
  },
  usersOnAnswer: {
    es: 'Usuarios en la respuesta',
    en: 'Users on answer',
    pt: 'Usuários na resposta'
  },
  createAnswer: {
    es: 'Crear Respuesta',
    en: 'Create Answer',
    pt: 'Criar Resposta'
  },
  advancedSearch: {
    criteria: {
      answerName: {
        en: 'Answer Name',
        es: 'Answer Name',
        pt: 'Answer Name',
      },
      answersBlocks: {
        en: 'Block',
        es: 'Block',
        pt: 'Block',
      },
      answerStatus: {
        en: 'Status',
        es: 'Status',
        pt: 'Status',
      },
      content: {
        en: 'Content',
        es: 'Content',
        pt: 'Content',
      },
      feedback: {
        en: 'Feedback',
        es: 'Feedback',
        pt: 'Feedback'
      },
      suggestion: {
        en: 'Suggestion',
        es: 'Suggestion',
        pt: 'Suggestion'
      },
      tag: {
        en: 'Tag',
        es: 'Tag',
        pt: 'Tag'
      },
      trainingContent: {
        en: 'Training Content',
        es: 'Training Content',
        pt: 'Training Content'
      },
      variableName: {
        en: "Variable Name",
        es: "Variable Name",
        pt: "Variable Name"
      },
    }
  },
  all: {
    es: 'Todas',
    en: 'All',
    pt: 'Todas',
  },
}