<template>
  <router-view
    v-if="loadedTheme"
    :key="$route.path"
  />
</template>

<script>
import { themeConfig } from '@/utils/themeConfig'
import update from './mixins/update'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'App',
  mixins: [update],
  data() {
    return {
      loadedTheme: false
    }
  },
  watch: {
    '$store.state.theme'(val) {
      this.toggleClassInBody(val)
    },
  },
  async mounted() {
    try {      
      await this.initConfig();
      this.loadedTheme = false
      this.$store.commit(`SET_ENVIRONMENT`, window.config.environment)
      this.$store.commit('SET_BACKEND', window.config.backend)
      this.$store.commit('SET_WIDGET_URL', window.config.widgetUrl)
      this.$store.commit(
        'SET_WIDGET_STAGING',
        window.config.widgetStaging || 'true'
      )
      this.$store.commit('SET_CHAT', window.config.chat)
      this.$store.commit('SET_VIDEOCALL', window.config.videocall)
      // if user logged in
      if (localStorage.session) {
        try { 
          await this.getCompanySettings()
        } catch (_) {
          // the okta-backend login it is made after that call
        }
      }
      this.$vs.theme(this.$store.state.themeColors)
      this.toggleClassInBody(themeConfig.theme)
      this.restartSession()
    } catch (error) {
      console.error(error)
    }
    this.loadedTheme = true

    await this.$socket.init(this.$store)
    this.updateSocket(this.$socket)

    this.preventVuesaxErrors()
  },
  beforeDestroy() {
    this.$socket.close()
  },
  methods: {
    ...mapActions([
      'updateAndSaveSession',
      'getCompanySettings',
      'updateSocket'
    ]),
    ...mapMutations(['CLEAR_SESSION']),
    async initConfig() {
      const res = await fetch(process.env.VUE_APP_CONFIG_FILE, { method: "get"});
      const config = await res.json()
      window.config = config;
    },
    toggleClassInBody(className) {
      if (className == 'dark') {
        if (document.body.className.match('theme-semi-dark'))
          document.body.classList.remove('theme-semi-dark')
        document.body.classList.add('theme-dark')
      } else if (className == 'semi-dark') {
        if (document.body.className.match('theme-dark'))
          document.body.classList.remove('theme-dark')
        document.body.classList.add('theme-semi-dark')
      } else {
        if (document.body.className.match('theme-dark'))
          document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark'))
          document.body.classList.remove('theme-semi-dark')
      }
    },
    restartSession() {
      try {
        const session = JSON.parse(localStorage.session)
        this.updateAndSaveSession(session)
      } catch (_) {
        this.CLEAR_SESSION();
      }
    },
    preventVuesaxErrors() {
      window.addEventListener('error', e => {
        // resize error
        if (
          e.origin === undefined && // Sonar
          e.filename.includes('vuesax/dist/vuesax.common.js') &&
          e.error.stack.includes('VueComponent.changeTdsWidth')
        ) {
          e.preventDefault()
        }
      })
    }
  },
}
</script>

<style lang="scss">
@import '../src/assets/scss/responsive.scss';
.tooltip {
  display: block !important;
  z-index: 1000000000;

  .tooltip-inner {
    background: var(--tooltip-default-background);
    color: var(--tooltip-default-text);
    border-radius: 12px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>
