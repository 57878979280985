<template>
  <div class="vx-card">
    <div
      v-if="hasHeader"
      class="vx-card__header"
    >
      <!-- card title -->
      <div>
        <h4 v-if="$props.title">
          {{ title }}
        </h4>
        <p
          v-if="$props.subtitle"
          class="s-text"
        >
          {{ subtitle }}
        </p>
      </div>
    </div>

    <div class="vx-card__collapsible-content vs-con-loading__container">
      <!-- content with no body(no padding) -->
      <slot name="no-body" />

      <!-- content inside body(with padding) -->
      <div
        v-if="$slots.default"
        class="vx-card__body"
      >
        <slot />
      </div>

      <div
        v-if="$slots.footer"
        class="vx-card__footer"
      >
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VxCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
  },
  computed: {
    hasHeader() {
      return this.title || this.subtitle
    },
  },
}
</script>

<style lang="scss" scoped>
.vx-card {
  width: 100%;
  background: #fff;
  border-radius: 0.5rem;
  display: block;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: all 0.3s ease-in-out;

  .vx-card__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1.5rem 1.5rem 0;
  }

  .vx-card__collapsible-content {
    transition: all 0.3s ease-in-out;

    &.vs-con-loading__container {
      overflow: unset;
    }
    .vx-card__body {
      padding: 30px;
      background-color: #fff;
    }
    :deep(img) {
      display: block;
    }
  }

  .vx-card__footer {
    padding: 0 1.5rem 1.5rem;
  }
}
</style>
