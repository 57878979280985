/** @type {import('vue').ObjectPlugin} */
export default {
  install(Vue) {
    const callNotify = message => {
      Vue._instance.proxy.$vs.notify(message)
    }

    const notify = {
      success(title, text) {
        callNotify({
          title,
          text,
          color: 'success',
          icon: 'icon-check-circle',
          iconPack: "symbolicon-font"
        })
      },
      warning(title, text) {
        callNotify({
          title,
          text,
          color: 'warning',
          icon: 'icon-warning',
          iconPack: "symbolicon-font"
        })
      },
      error(title, text) {
        callNotify({
          title,
          text,
          color: 'danger',
          icon: 'icon-warning',
          iconPack: "symbolicon-font"
        })
      }
    }

    Vue.config.globalProperties.$notify = notify
  }
}
