/**
 * @link https://vite-pwa-org.netlify.app/frameworks/vue.html#vue-2
 * 
 * This mixin is used to update the service worker when a new version is available.
 */
export default {
  data() {
    return {
      updateSW: undefined,
      needRefresh: false
    }
  },
  watch: {
    /**
     * Watcher to trigger the update popup
     * 
     * @param {boolean} val 
     */
    needRefresh(val) {
      if (!val) return
      const lang = this.$store.state.lang
      const languageSelected = this.$store.state.languageSelected
      this.$dialog({
        type: 'alert',
        closeable: false,
        closeOnBlur: false,
        color: 'warning',
        title: lang.login.serviceWorker.updateTitle[languageSelected],
        text: lang.login.serviceWorker.updateText[languageSelected],
        acceptText: lang.login.serviceWorker.updateButton[languageSelected],
        accept: () => {
          this.updateServiceWorker()
        }
      })
    },
  },
  async mounted() {
    try {
      const { registerSW } = await import('virtual:pwa-register')
      const vm = this
      // Register the service worker instance and add the event listeners
      this.updateSW = registerSW({
        immediate: true,
        onRegisterError(e) {
          console.error(e)
        },
        onNeedRefresh() {
          vm.needRefresh = true
        },
      })
    } catch {
      console.log('PWA disabled.')
    }
  },
  methods: {
    updateServiceWorker() {
      // Only update if the service worker is available
      if (this.updateSW) this.updateSW(true)
    },
  }
}