import Request from './request'
import state from '../store/state'

import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'

const PATH = {
  TAG: '/tags'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getAllTags = ( offset = 0, limit = 100, text=null) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  let query = ''

  query = `?offset=${offset}&limit=${limit}`

  if(text){
    query = `${query}&q=${text}`
  }

  return Request.get(`${state.config.konecta}${PATH.TAG}/search${query}`, config)
}

const getTagsAll = (queryTag) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  let query = queryTag;
  
  return Request.get(`${state.config.konecta}${PATH.TAG}${query}`, config)
}

const getTags = (offset= 0, limit = 500, versionId=null, text=null, ninServiceId = false) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  let query=`?offset=${offset}&limit=${limit}`

  if(versionId !== null){
    if(Array.isArray(versionId)){
      versionId.forEach((serviceId)=>{
        query += `&where[service]${ninServiceId ? '[$nin]': ''}=${serviceId}`
      })
    }else{
      query+=`&where[version]${ninServiceId ? '[$nin]': ''}=${versionId}`
    }
  }

  query+= '&populate[path]=tagCategory'

  if (text) {
    query = `${query}&where[name][$contains]=${text}`;
  }
  
  return Request.get(`${state.config.konecta}${PATH.TAG}${query}`, config)
}

const getTag = tagId => {
    const token = _getToken()
    const config = {
        headers: {
            authorization: `Bearer ${token}`
        }
    }

    return Request.get(`${state.config.konecta}${PATH.TAG}/${tagId}`, config)
}

const createTag = payload => {
  const token = _getToken()
  const config = {
      headers: {
          authorization: `Bearer ${token}`
      }
  }
  return Request.post(`${state.config.konecta}${PATH.TAG}`, omitBy(payload, isNil), config)
}

const updateTag = (id, payload) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.put(`${state.config.konecta}${PATH.TAG}/${id}`, omitBy(payload, isNil), config)
}

const deleteTag = tagId => {
    const token = _getToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.delete(
      `${state.config.konecta}${PATH.TAG}/${tagId}`, config
    )
  }

export default {
  getTags,
  getTag,
  getAllTags,
  getTagsAll,
  createTag,
  updateTag,
  deleteTag
}
